import React, { useState, useEffect } from 'react';

const CekKoneksi = () => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component

    if (!isOnline) {
        return (
            <>
                <div className="bg-red-100">
                    <div className="mx-auto max-w-7xl py-1 px-3 sm:px-6 lg:px-8">
                        <div className="text-center text-red-600 text-sm md:text-base">
                            Tidak ada koneksi internet
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CekKoneksi;