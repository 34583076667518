import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import ArticleList from "./ArticleList";

export default function ArticleDetail() {
    const uri_param = useParams();
    const slug = uri_param.slug;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [article, setArticle] = useState([]);
    const [title, setTitle] = useState('');
    document.title = title;

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}article/${slug}`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setArticle(result.data);
                        setTitle(result.data.title)
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [slug])

    let content = '';
    if (error) {
        content = <div className="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">{error.message} @DetailArticle</div>
    } else if (!isLoaded) {
        content = <div className="group animate-pulse">
            <div className="bg-slate-300 rounded-xl h-96 w-full">&nbsp;</div>
        </div>
    } else {
        content = <article>
            <header className="flex flex-col">
                <p className="mb-6 text-3xl font-bold tracking-tight text-zinc-800 sm:text-4xl">{article.title}</p>
                <time className="flex items-center text-base text-zinc-400 mb-6">
                    <span className="h-4 w-0.5 rounded-full bg-zinc-200"></span>
                    <span className="ml-3">{article.date_post} - {article.author}</span>
                </time>
                <img src={article.img_cover} alt={article.title} className=" w-full" />
            </header>
            <div className="mt-8 prose" data-mdx-content="true">
                {HTMLReactParser(article.content)}
            </div>
        </article>
    }

    return (
        <Layout>
            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-y-20 lg:max-w-none md:grid-cols-3 lg:grid-cols-5">
                    <div className="md:col-span-2 lg:col-span-3 gap-16">
                        {content}
                    </div>
                    <div className="space-y-10 md:pl-8 xl:pl-24 md:col-span-1 lg:col-span-2">
                        <ArticleList limit='8' />
                    </div>
                </div>
            </div>
        </Layout>
    )
}