import { Link } from "react-router-dom"

export default function PageNotFound() {
    return (
        <div>
            <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 w-full lg:pb-28 xl:pb-32">
                    <main className="mx-auto w-full px-4 pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8">
                        <div className="text-center">
                            <h1 className="text-2xl tracking-tight font-bold text-gray-900 sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
                                <div className="text-blue-600 mb-3">404</div>
                                <div className="text-2xl">Halaman Tidak Ditemukan</div>
                            </h1>
                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                Maaf, kami tidak dapat menemukan halaman yang Anda tuju.
                            </p>
                            <div className="mt-8 flex justify-center">
                                <Link to={'/'} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3">Kembali ke Home</Link >
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
