import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function CategoryGallery({ ...props }) {
    const slug = props.slug;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}category-gallery`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    const [selected, setSelected] = useState(slug)
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelected(selectedValue);
        navigate(`/gallery/category/${selectedValue}`, { replace: true })
    };


    let category = '';
    if (error) {
        category = <div className="bg-slate-300 rounded-xl h-6 px-2 py-4 w-full text-gray-500 flex items-center justify-center">{error.message} @Category</div>
    } else if (!isLoaded) {
        category = <div className="group animate-pulse w-full">
            <div className="bg-slate-300 h-8 w-52 rounded-xl">&nbsp;</div>
        </div>
    } else {
        category =
            (datas.length > 0) ?
                <select id="category" onChange={handleSelectChange} defaultValue={(selected) ? selected : 'Choose category'} className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <option value="Choose category" disabled>Choose category</option>
                    {datas.map((option, index) => (
                        <option key={index} value={option.slug}>{option.name}</option>
                    ))}
                </select>
                :
                <select id="category" onChange={handleSelectChange} defaultValue={'Choose category'} className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <option value="Choose category" disabled>No category</option>
                </select>
    }

    return (
        <>
            <label htmlFor="category" className="me-5">Select Category</label>
            <div>{category}</div>
        </>
    )
}