import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import BtnPrevious from "../../Paging/BtnPrev";
import BtnNext from "../../Paging/BtnNext";
import CategoryDocument from "./CategoryDocument";


export default function Document() {
    document.title = 'Documents';
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [totalPages, setTotalPages] = useState(0)
    const [page, setPage] = useState(1)
    const [btnPrevStatus, setBtnPrevStatus] = useState('inactive')
    const [btnNextStatus, setBtnNextStatus] = useState('inactive')

    useEffect(() => {
        (page <= 1) ? setBtnPrevStatus('inactive') : setBtnPrevStatus('active')
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}document?page=${page}`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setIsLoaded(true);
                    if (result.status) {
                        setDocuments(result);
                        if (result.total) {
                            setTotalPages(Math.ceil(result.total / 12));
                            (page >= totalPages) ? setBtnNextStatus('inactive') : setBtnNextStatus('active')
                        }
                        else {
                            setBtnPrevStatus('inactive')
                            setBtnNextStatus('inactive')
                        }
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [page, totalPages])

    let content = '';
    if (error) {
        content = <div className="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">{error.message} @Document</div>
    } else if (!isLoaded) {
        content = <div className="group animate-pulse">
            <div className="grid grid-cols-2 gap-x-6 gap-y-10 md:grid-cols-3 lg:grid-cols-4">
                <div className="bg-slate-300 rounded-xl h-56">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-56">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-56">&nbsp;</div>
                <div className="bg-slate-300 rounded-xl h-56">&nbsp;</div>
            </div>
        </div>
    } else {
        content =
            (documents.data.length > 0) ?
                <>
                    {
                        <div className="grid grid-cols-2 gap-x-6 gap-y-10 md:grid-cols-3 lg:grid-cols-4">
                            {
                                documents.data.map((list, i) => (
                                    <div key={i} className="group relative border border-zinc-100 hover:bg-slate-50 rounded-2xl p-8">
                                        <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-red-50 shadow-md shadow-red-800/5 ring-1 ring-red-900/5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-file-pdf text-red-600" viewBox="0 0 16 16">
                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                                <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                            </svg>
                                        </div>
                                        <div className="mt-6">
                                            <h3 className="text-gray-800 font-semibold">
                                                <span aria-hidden="true" className="absolute inset-0" />
                                                {list.name}
                                            </h3>
                                            <p className="text-gray-400 text-xs">{list.date_post}</p>
                                            <a href={list.filename} target={`_doc${i}`} rel="noopener noreferrer" className="relative z-10 mt-6 flex items-center text-sm font-medium text-zinc-400 transition group-hover:text-blue-500">
                                                <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 flex-none">
                                                    <path d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z" fill="currentColor"></path>
                                                </svg>
                                                <span className="ml-2">View Document</span>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className="flex justify-center mt-20 gap-3">
                        <BtnPrevious status={btnPrevStatus} fn={() => setPage(page - 1)} />
                        <BtnNext status={btnNextStatus} fn={() => setPage(page + 1)} />
                    </div>
                </>
                : <><div className=" bg-red-50 p-6 rounded-lg text-center w-full">{documents.message}</div></>
    }


    return (
        <Layout>
            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="md:flex justify-between mb-8">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-4 md:mb-0">Documents</h2>
                    <div className="flex items-center">
                        <CategoryDocument />
                    </div>
                </div>
                {content}
            </div>
        </Layout >
    )
}
