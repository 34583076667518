import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CategoryArticle({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_BARIER_TOKEN}` };
        fetch(`${process.env.REACT_APP_URL_API}category-article`, { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const filtered = result.data.slice(0, limit);
                        setDatas(filtered);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])


    let category = '';
    if (error) {
        category = <div className="bg-slate-300 rounded-xl h-12 px-6 py-10 w-full text-gray-500 flex items-center justify-center">{error.message} @Category</div>
    } else if (!isLoaded) {
        category = <div className="group animate-pulse w-full grid gap-4">
            <div className="bg-slate-300 rounded-xl h-12">&nbsp;</div>
            <div className="bg-slate-300 rounded-xl h-12">&nbsp;</div>
            <div className="bg-slate-300 rounded-xl h-12">&nbsp;</div>
            <div className="bg-slate-300 rounded-xl h-12">&nbsp;</div>
            <div className="bg-slate-300 rounded-xl h-12">&nbsp;</div>
        </div>
    } else {
        category =
            datas.map((list, i) => (
                <Link key={i} to={`/article/category/${list.slug}`} state={{ nm_category: list.name }} className="flex cursor-pointer justify-between gap-x-6 py-2 text-gray-900 hover:text-blue-700">
                    <div className="flex min-w-0 gap-x-2">
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm leading-6 ">{list.name}</p>
                        </div>
                    </div>
                </Link>
            ))
    }

    return (
        <div className="rounded-2xl border border-zinc-100 p-6">
            <h2 className="flex items-center text-sm font-semibold text-zinc-900 mb-8">
                <RectangleStackIcon className="h-6 w-6" aria-hidden="true" />
                <span className="ml-3 text-xl">Categories</span>
            </h2>
            <div className="divide-y divide-gray-100">
                {category}
            </div>
        </div>
    )
}