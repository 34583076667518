export default function BtnPrevious({ ...props }) {
    const status = props.status;
    const fn = props.fn;

    // useEffect(() => {
    //     if (status === 'active') {
    //         document.getElementById('btnPrev').classList.remove('hover:bg-zinc-50', 'text-zinc-400');
    //         document.getElementById('btnPrev').classList.add('hover:bg-zinc-100', 'text-zinc-900');
    //         document.getElementById('btnPrev').disabled = false
    //     } else {
    //         document.getElementById('btnPrev').classList.remove('hover:bg-zinc-100', 'text-zinc-900');
    //         document.getElementById('btnPrev').classList.add('hover:bg-zinc-50', 'text-zinc-400');
    //         document.getElementById('btnPrev').disabled = true
    //     }
    // }, [status])

    let btnNext = '';
    btnNext = (status === 'active') ?
        <button onClick={fn} id="btnNext" className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 bg-zinc-50 text-zinc-900 font-medium border border-gray-100 w-32">&laquo; Prev</button>
        :
        <button id="btnNext" className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 bg-zinc-50 text-zinc-400 font-medium border border-gray-100 w-32" disabled>&laquo; Prev</button>

    return (
        <>
            <div>
                {btnNext}
            </div>
        </>
    );
}