
import { Route, Routes } from 'react-router-dom';
import Home from './components/Page/Home/Home';
import PageNotFound from './components/Page/PageNotFound';
import Gallery from './components/Page/Gallery/Gallery';
import About from './components/Page/About/About';
import Document from './components/Page/Document/Document';
import Article from './components/Page/Article/Article';
import ArticleDetail from './components/Page/Article/ArticleDetail';
import ArticleByCategory from './components/Page/Article/ArticleByCategory';
import DocumentByCategory from './components/Page/Document/DocumentByCategory';
import GalleryByDocument from './components/Page/Gallery/GalleryByCategory';

function App() {
  return (
    <div className="h-screen">
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/article' element={<Article />}></Route>
        <Route path='/article/category/:slug' element={<ArticleByCategory />}></Route>
        <Route path='/article/detail/:slug' element={<ArticleDetail />}></Route>
        <Route path='/document' element={<Document />}></Route>
        <Route path='/document/category/:slug' element={<DocumentByCategory />}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
        <Route path='/gallery/category/:slug' element={<GalleryByDocument />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='*' element={<PageNotFound />}></Route>
      </Routes>
    </div>
  )
}

export default App;
