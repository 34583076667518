import CekKoneksi from "./CekKoneksi";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

export default function Layout({ children }) {
    return (
        <>
            <CekKoneksi />
            <div className="min-h-full">
                <Navbar />
                <main>
                    {children}
                </main>
                <Footer />
            </div>
        </>
    )
}
